import { useEventListener } from '@vueuse/core';
import { openNewsletterModal } from '@/js/pages/helpers/openNewsletterModal';
import { getCookieValue, setCookie } from '@/js/helpers/cookieHelpers';

const COOKIE_KEY = 'newsletter-modal-scroll';
const SCROLL_THRESHOLD = 1500;

const checkIfModalIsAllowed = () => {
    const cookieValue = getCookieValue(COOKIE_KEY);

    if (cookieValue) {
        return false;
    }

    setCookie(COOKIE_KEY, 'true', 14);

    return true;
};

const openNewsletterOnScroll = () => {
    if (!checkIfModalIsAllowed()) {
        return;
    }

    const disableEvent = useEventListener(window, 'scroll', () => {
        if (window.scrollY >= SCROLL_THRESHOLD) {
            openNewsletterModal();
            disableEvent();
        }
    }, { passive: true });
};

useEventListener(document, 'DOMContentLoaded', openNewsletterOnScroll);
